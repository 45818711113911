/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  ActiveProductContext,
  ImsProfile,
  ProjectedProductContext
} from '@adobe/exc-app/ims/ImsProfile';
import {
  addParamToUrl,
  filterSearchParams,
  getPathWithoutContext,
  getPathWithoutTenant,
  hashToPath,
  removeQueryParamsFromPath
} from '@exc/url';
import type {AppSandbox} from '../models/AppSandbox';
import {
  CACHE_SCOPE,
  CachedSession,
  DiscoveryCacheConfig,
  DiscoveryOptions,
  DiscoveryPaths,
  DiscoveryPayload,
  DiscoveryResponse,
  DiscoveryResponseSource,
  DiscoverySource,
  FindHostFromContextConfig,
  HostParams,
  PATH_CACHE_METHOD
} from '../models/Discovery';
import {CoreContext} from '../models/Context';
import {debugTokenStats} from '../utils/debug';
import {DefaultMetaData, fetch, FetchInit, FetchScope} from '@adobe/exc-app/network';
import {getAppEnvironment, updateTemplatedURL} from '../utils/appSandboxUtils';
import {getBootstrap} from './BootstrapService';
import {getHistoryType, staleWhileRevalidate} from '../utils';
import {getLocalCache} from '@exc/storage';
import {getTokenRefreshData} from '@exc/auth';
import hashString from 'string-hash';
import {HISTORY} from '../models/Solution';
import type {IMS} from '@adobe/exc-app/user';
import {isSkyline} from '@exc/url/skyline';
import metrics, {Level} from '@adobe/exc-app/metrics';
import type {Section} from '../models/Solution';
import type {Session} from '@adobe/exc-app/session';
import sessionService from './SessionService';

// If cached entry is less than 15 minutes old, skip "stale-while-revalidate"
const FRESHNESS_THRESHOLD = 900 * 1000; // 15 minutes.
const STORAGE_KEY = 'unifiedShellDiscovery';
const localCache = getLocalCache<DiscoveryResponse|CachedSession>(STORAGE_KEY);

/**
 * Before we hash the Discovery payload, we need to transform non-string elements
 * of the payload to strings to ensure the hash will always map to the same value.
 *
 * The non-string keys are Active Product Context and IMS profile.
 * The object below contains their transformation into unique keys.
 */
const PAYLOAD_TRANSFORM: Record<string, (value: any) => string> = {
  activeProductContext: (apc: ActiveProductContext) => Object.keys(apc)
    .map(serviceCode => `${serviceCode}:${apc[serviceCode].ident || ''}:${apc[serviceCode].owningEntity || ''}`)
    .join(','),
  imsProfile: (imsProfile: ImsProfile) => imsProfile.userId
};

const getSid = (): string => getBootstrap().getSid() || 'unknown';

export class DiscoveryError extends Error {
  public readonly errorInfo?: Record<string, string>;
  public readonly status?: number;

  constructor(message: string, status?: number, _errorInfo?: Record<string, string>) {
    super(message);
    this.errorInfo = _errorInfo;
    this.status = status;
  }
}

export interface DiscoveryErrorMessage {
  description?: string;
  heading?: string;
  id: string;
}

export interface PostErrorAction {
  showErrorPage: boolean;
  error?: DiscoveryErrorMessage;
}

export interface DiscoverySandboxResponse {
  customIms?: IMS;
  hasUrl: boolean;
  src: URL
}

export class DiscoveryService {
  private readonly promises: {[key: string]: Promise<DiscoveryResponse>} = {};
  private readonly metrics = metrics.create('exc.core.services.DiscoveryService');

  /**
   * Adds a random request ID to the Discovery URL
   * @param {string} url - Discovery server URL
   * @returns {string} - Discovery server URL with random request ID added.
   * @private
   */
  private static addRequestId(url: string): string {
    return addParamToUrl(url, 'requestId', Math.random().toString().split('.')[1]);
  }

  /**
   * Calculates discovery paths, including removal of unwanted query parameters.
   * @param {DiscoveryCacheConfig} cacheConfig - Discovery cache configuration
   * @param {DiscoveryOptions} options
   * @returns {[string, string]} fullPath and path
   * @private
   */
  private getDiscoveryPaths(cacheConfig: DiscoveryCacheConfig|undefined, options: DiscoveryOptions): DiscoveryPaths {
    const {removeParams = []} = cacheConfig || {};
    const {basePath} = options;
    const currentUrl = hashToPath();
    const pathWithoutContext = getPathWithoutContext(currentUrl).replace(getPathWithoutContext(basePath), '');
    let path = getPathWithoutTenant(pathWithoutContext);
    const fullPath = getPathWithoutTenant(currentUrl);
    removeParams.length && (path = removeQueryParamsFromPath(path, removeParams));
    return {fullPath, path};
  }

  private profileReducer = ({
    account_type,
    authId,
    countryCode,
    displayName,
    email,
    emailVerified,
    first_name,
    job_function,
    last_name,
    name,
    preferred_languages,
    session,
    userId
  }: ImsProfile): ImsProfile => ({
    account_type,
    authId,
    countryCode,
    displayName,
    email,
    emailVerified,
    first_name,
    job_function,
    last_name,
    name,
    preferred_languages,
    projectedProductContext: [],
    session,
    userId
  });

  /**
   * Returns base payload without paths.
   * @param {CoreContext} context - Context object
   * @returns Discovery Payload.
   */
  private getBasePayload(context: CoreContext): DiscoveryPayload {
    const {activeProductContext, imsOrg, imsProfile, locale, tenant, subOrg} = context;
    return {
      activeProductContext,
      company: subOrg?.name || '',
      imsOrg,
      imsProfile,
      locale,
      tenant
    };
  }

  /**
   * Returns the discovery options for a given application
   * @param app App Sandbox object
   * @returns Discovery options
   */
  public getDiscoveryOptions(app: AppSandbox): DiscoveryOptions {
    const {basePath, context, solutionConfig} = app;
    const {appId, sections, serviceCode, session: sessionConfig} = solutionConfig;
    return {
      appEnv: getAppEnvironment(context, solutionConfig),
      appId,
      basePath,
      history: getHistoryType(solutionConfig),
      sections,
      serviceCode,
      sessionConfig
    };
  }

  /**
   * Checks if the context has all data needed to generate discovery payload.
   * @param source - Solution discovery source definition
   * @param app App Sandbox object.
   * @returns true if all data needed to generate discovery payload is available.
   */
  public canGeneratePayload(source: DiscoverySource, app: AppSandbox): boolean {
    const {context, solutionConfig: {serviceCode}} = app;
    const {subOrg} = context;
    const {payload: payloadKeys, requiredPayload} = source;
    const payload = this.getBasePayload(context);
    const {activeProductContext, imsOrg} = payload;
    const properties = requiredPayload || payloadKeys || [];

    for (const key of properties) {
      if (!['fullPath', 'path'].includes(key) && !payload[key]) {
        return false;
      }

      if (key === 'activeProductContext' && serviceCode &&
        !(imsOrg && activeProductContext?.[serviceCode]?.owningEntity === imsOrg)) {
        imsOrg && this.metrics.event(
          'Discovery.apcOrgMismatch',
          {imsOrg, owningEntity: activeProductContext?.[serviceCode]?.owningEntity},
          {level: Level.WARN}
        );
        return false;
      }

      if (key === 'company' && !(imsOrg && subOrg?.owningEntity === imsOrg)) {
        imsOrg && this.metrics.event(
          'Discovery.subOrgMismatch',
          {imsOrg, owningEntity: subOrg?.owningEntity},
          {level: Level.WARN}
        );
        return false;
      }
    }
    return true;
  }

  /**
   * Generates Discovery payload
   * @param {DiscoverySource} source - Solution discovery source definition
   * @param {DiscoveryPaths} paths - Discovery path & full path
   * @param {CoreContext} context - Context object
   * @returns {DiscoveryPayload} Discovery request payload
   * @private
   */
  private getDiscoveryPayload(source: DiscoverySource, paths: DiscoveryPaths, context: CoreContext): DiscoveryPayload {
    const {cache = {} as DiscoveryCacheConfig, payload: properties = []} = source;
    const {keepParams = [], pathCacheMethod = PATH_CACHE_METHOD.CACHE_PATH} = cache;
    let {path} = paths;
    const {imsProfile} = context;
    if (pathCacheMethod !== PATH_CACHE_METHOD.CACHE_PATH) {
      // Ignore the path completely, only keep specific query parameters (If configured).
      const url = new URL(`${window.location.origin}${path}`);
      path = filterSearchParams(url.searchParams, keepParams);
    }
    const payload: DiscoveryPayload = {...this.getBasePayload(context), ...paths, path};

    if (properties.length) {
      const reducedPayload: DiscoveryPayload = {};
      properties.forEach(key => reducedPayload[key] = payload[key] as any);

      // Copy the property or sub property from payload to reducedPayload
      if (source.reduceProfile && properties.includes('imsProfile')) {
        reducedPayload.imsProfile = this.profileReducer(imsProfile);
      }

      return reducedPayload;
    }
    return payload;
  }

  /**
   * Returns a unique key for given payload, achieved through a one way hash.
   * @param {DiscoveryPayload} payload - Discovery payload
   * @param {DiscoverySource} source - Discovery configuration
   * @param {string} userId - Core context userId
   * @param {string} appId - Application ID
   * @returns {string} key (hash)
   * @private
   */
  private getKey(payload: DiscoveryPayload, source: DiscoverySource, userId: string, appId: string): string {
    const payloadForKey: Record<string, string> = {};
    const payloadToTransform: Record<string, any> = payload;
    const payloadKeys = source.requiredPayload || source.payload;

    Object.keys(payload)
      .filter(key => payloadKeys.includes(key as keyof DiscoveryPayload))
      .forEach(key => payloadForKey[key] = (PAYLOAD_TRANSFORM[key] && payloadToTransform[key] ?
        PAYLOAD_TRANSFORM[key](payloadToTransform[key]) :
        payloadToTransform[key]));

    const discoveryInfo = JSON.stringify({payload: payloadForKey, source, userId});
    const hash = hashString(discoveryInfo);
    return `${appId}-${hash}`;
  }

  private getSkylineKey({imsSessionId}: CoreContext, source: DiscoverySource, appId: string): string {
    return `${appId}-${hashString(JSON.stringify({payload: {imsSessionId}, source}))}`;
  }

  /**
   * Returns true if the given date is considered "fresh" (Recent).
   * @param {DiscoveryResponse|undefined} response - Discovery response
   * @param {boolean} sessionConfigured - Is session used with this discovery?
   * @returns true if fresh, false otherwise.
   * @private
   */
  private isFresh(response: DiscoveryResponse, sessionConfigured = false): boolean {
    let expiryForFreshness = response.fulfilled + FRESHNESS_THRESHOLD;
    if (sessionConfigured && response.session && response.sessionExpires) {
      // If we have a valid session, we don't want to rerun discovery before it expires.
      expiryForFreshness = response.sessionExpires;
    }
    return expiryForFreshness > Date.now();
  }

  /**
   * Returns an existing response for a discovery request.
   * Looks at the cache first, and then at pending requests.
   * @param key - unique key (Provided by getKey)
   * @param skipCache - Skip cache (Used for new sessions)
   * @param cache - Discovery cache config
   * @returns existing response if available.
   * @private
   */
  private async getExistingResponse(key: string, skipCache: boolean, cache: DiscoveryCacheConfig): Promise<{
    from: DiscoveryResponseSource;
    response: DiscoveryResponse;
  }|undefined> {
    try {
      // Look for existing result (or pending result) in memory
      const existingPromise = this.promises[key];
      let response;
      let from = DiscoveryResponseSource.DIRECT;
      // Look for cached result. Note localCache already verifies expiry.
      if (!skipCache) {
        response = await localCache.get(key) as DiscoveryResponse;
        if (response && cache.limitToImsSession) {
          const {session} = response;
          if (session !== getSid()) {
            response = undefined;
          }
        }
        from = DiscoveryResponseSource.CACHE;
      }

      // If no cached, use a pending response if we have one.
      if (existingPromise && !response) {
        response = await existingPromise;
        from = DiscoveryResponseSource.MEMORY;
      }

      if (response) {
        return {from, response};
      }
    } catch (err) {
      this.metrics.warn('Failed to get Discovery response from cache or memory', {err});
    }
  }

  private getSectionFromResponse(response: DiscoveryResponse, options: DiscoveryOptions): Section|undefined {
    if (options.sections && response.url) {
      const url = new URL(response.url);
      return options.sections.find(section => url.pathname.startsWith(section.route));
    }
  }

  /**
   * Augment Path with URL by copying the origin and query parameters from the URL onto the path.
   * @param path - Path to augment
   * @param url - Input URL
   * @param method - Path caching method.
   * @param history - SPA History config
   * @returns Output URL combining the original path with input URL origin and parameters
   */
  private augmentPathWithURL(path: string, url: string, method: PATH_CACHE_METHOD, {history = HISTORY.HISTORY}: DiscoveryOptions): string {
    const inputUrl = new URL(url);
    const outputUrl = new URL((history === HISTORY.HASH && path && path !== '/') ? `#${path}` : path, inputUrl.origin);
    if (method === PATH_CACHE_METHOD.CACHE_PARAMS) {
      // Copy origin and query params from input url.
      inputUrl.searchParams.forEach((value, name) =>
        outputUrl.searchParams.set(name, value)
      );
    }
    if (method === PATH_CACHE_METHOD.CACHE_DEFAULT_PATH) {
      // If path is empty, copy the entire URL, otherwise copy origin only.
      if (!path || path === '/') {
        return url;
      }
    }
    return outputUrl.toString();
  }

  public findDomainFromContext = (
    {org, projectedProductContext, subOrg}: HostParams,
    findHostFromContext: FindHostFromContextConfig
  ) => {
    let domain: string | undefined;
    const {getHostFromContext, useAdobeDomain} = findHostFromContext;
    projectedProductContext.some(({prodCtx}: ProjectedProductContext) => {
      const {serviceCode, owningEntity} = prodCtx;
      // Note: fulfillable_data may not be the location to find the information for future apps.
      // We may want to add an extra config in the future for where to check or otherwise
      // handle other cases.
      if (owningEntity !== org || serviceCode !== findHostFromContext.serviceCode) {
        return;
      }
      domain = getHostFromContext(prodCtx, subOrg);
      return domain;
    });
    if (domain && useAdobeDomain) {
      const domainPart = domain.slice(0, domain.lastIndexOf('.'));
      const topLevelDomain = domain.slice(domain.lastIndexOf('.') + 1);
      if (domain.endsWith(`adobe.${topLevelDomain}`)) {
        return domain;
      }
      return `${domainPart}.adobe.${topLevelDomain}`;
    }
    return domain;
  };

  private async needNewDiscoveryForSession(
    response: DiscoveryResponse,
    source: DiscoverySource,
    context: CoreContext,
    options: DiscoveryOptions,
    isCache: boolean
  ): Promise<boolean> {
    const {appId} = options;
    const section = this.getSectionFromResponse(response, options);
    if (section && response.url) {
      const {sessionParameter} = section;
      if (sessionParameter) {
        const session = await sessionService.getSession(context, options);
        if (!session) {
          if (isCache) {
            // No usable session. We need to do this again with cache disabled.
            return true;
          }
          // This should not happen as the discovery endpoint is expected to return a session.
          // Report this.
          this.metrics.event('Discovery.sessionMissing', {appId, discovery: source.discovery}, {level: Level.ERROR});
        }
        if (session) {
          response.url = addParamToUrl(response.url, sessionParameter, session.id);
        }
      }
    }
    return false;
  }

  /**
   * Executes a discovery request.
   * @param {DiscoverySource} source - Solution discovery source definition
   * @param {CoreContext} context - Context object
   * @param {DiscoveryOptions} options - Additional discovery options
   * @returns {DiscoveryResponse} Discovery response
   */
  public async getSourceFromDiscovery(source: DiscoverySource, context: CoreContext, options: DiscoveryOptions): Promise<DiscoveryResponse> {
    const {appId} = options;
    const {cache = {scope: CACHE_SCOPE.NONE}, findHostFromContext, withCredentials = false} = source;
    const {pathCacheMethod = PATH_CACHE_METHOD.CACHE_PATH} = cache;
    const paths = this.getDiscoveryPaths(cache, options);
    let payload: DiscoveryPayload = this.getDiscoveryPayload(source, paths, context);
    const discoveryTimer = this.metrics.start('Discovery');
    const key = isSkyline() ?
      this.getSkylineKey(context, source, appId) :
      this.getKey(payload, source, context.imsProfile?.userId, appId);
    let host = '';

    // Populating host of discovery call if needed.
    if (findHostFromContext) {
      const {imsOrg, imsProfile, subOrg} = context;
      host = this.findDomainFromContext({
        org: imsOrg,
        projectedProductContext: imsProfile?.projectedProductContext,
        subOrg
      }, findHostFromContext) as string;
      payload = {};
    }

    try {
      const {path = ''} = paths;
      let extraLogging = {};
      let isFresh = false;
      const sessionRequest = options.sessionRequest && source.includesSession;
      let {from, response} = await this.getExistingResponse(key, !!sessionRequest, cache) || {};

      if (response) {
        const {expires, fulfilled} = response;
        let cachedSession: Session|undefined;
        if (source.includesSession) {
          cachedSession = await sessionService.getSession(context, options);
          if (cachedSession) {
            response.session = cachedSession.id;
            response.sessionExpires = cachedSession.expires;
          }
        }
        isFresh = cache.scope === CACHE_SCOPE.NONE || this.isFresh(response, source.includesSession);
        extraLogging = {expires, fulfilled, isFresh};
      }
      from = from || DiscoveryResponseSource.DIRECT;

      debugTokenStats(this.metrics, 'DiscoveryService getSourceFromDiscovery', context.imsToken, {
        isFresh,
        responseExists: !!response,
        withCredentials
      });

      // If we don't have a response yet, or it is not recent - Get fresh.
      if (!(response && isFresh)) {
        const getDiscovery = (): Promise<DiscoveryResponse|undefined> => {
          const discoveryPromise = this.fetchDiscovery(source, payload, context)
            .then(discoveryResponse => sessionService.getSessionFromResponse(discoveryResponse, source, context, options))
            .then(discoveryResponse => this.onComplete(key, discoveryResponse, cache));
          const logError = !!response;
          this.promises[key] = discoveryPromise;
          // This method will log errors if waiting for the promise.
          // Otherwise, onError will do it.
          discoveryPromise
            .catch(error => {
              logError && this.onError(key, source, error);
            })
            .finally(() => delete this.promises[key]);
          return discoveryPromise;
        };
        // If we have no response, wait for this promise.
        // Otherwise, it used for a future request (stale-while-revalidate)
        response = await staleWhileRevalidate<DiscoveryResponse|undefined>(getDiscovery, response);
      }

      // Clone the response so any downstream code will not manipulate it directly.
      response = {host, ...(response as DiscoveryResponse)};
      // Update URL if needed.
      if (response.url && pathCacheMethod !== PATH_CACHE_METHOD.CACHE_PATH) {
        response.url = this.augmentPathWithURL(path, response.url, pathCacheMethod, options);
      }

      // Does this discovery include a session? Needs special handling then.
      if (source.includesSession && options.sections) {
        const needNewDiscovery = await this.needNewDiscoveryForSession(
          response, source, context, options, from === DiscoveryResponseSource.CACHE
        );

        if (needNewDiscovery) {
          // No usable session. We need to do this again with cache disabled.
          return this.getSourceFromDiscovery(source, context, {...options, sessionRequest: true});
        }
      }
      const {activeProductContext, imsOrg, tenant} = payload;
      const fromString = from !== DiscoveryResponseSource.DIRECT ? `.${from}` : '';
      const successEvent = `success${sessionRequest ? '.session' : ''}${fromString}`;

      discoveryTimer.time(successEvent, {
        ...extraLogging,
        ...getTokenRefreshData(context.imsToken),
        activeProductContext,
        appId,
        imsOrg,
        path: payload.path,
        response,
        tenant,
        url: response.discoveryUrl
      });
      if (source.next && response.url) {
        const nextSource = {...source.next};
        const {origin} = new URL(response.url);
        nextSource.discovery = nextSource.discovery.replace('{lastOrigin}', origin);
        return this.getSourceFromDiscovery(nextSource, context, options);
      }
      return response;
    } catch (error: any) {
      const message = error?.message || 'Unknown error';
      const url = error?.url || source.discovery;
      discoveryTimer.time('error', {
        appId,
        cookie: document.cookie.length,
        error: error?.errorInfo,
        message,
        url
      }, {level: Level.ERROR});

      // If the error is a DiscoveryError, it means there is more information to
      // be used by the caller function.
      if (error instanceof DiscoveryError) {
        throw error;
      }
      const discoveryError = new Error(`Failed to fetch url: ${message}`);
      (discoveryError as any).status = error?.status;
      throw discoveryError;
    }
  }

  private async extractDiscoveryError(response: Response, source: DiscoverySource): Promise<DiscoveryError> {
    let errorContent = await response.text();
    const status = response.status;
    try {
      let error = JSON.parse(errorContent);
      if (error.errorDescription) {
        errorContent = error.errorDescription;
      }
      // Get app-specific error information from the error message if the app is
      // participating in this feature (e.g. the getErrorInfo function exists).
      if (source.getErrorInfo) {
        error = {...error, ...source.getErrorInfo(error)};
      }
      return new DiscoveryError(`Status: ${response.status} Response: ${errorContent}`, status, error);
    } catch {
      return new DiscoveryError(`Status: ${response.status} Response: ${errorContent}`, status);
    }
  }

  private async fetchDiscovery(source: DiscoverySource, payload: DiscoveryPayload, context: CoreContext): Promise<DiscoveryResponse> {
    let {discovery: url = ''} = source;
    let scope = FetchScope.AUTH;
    let body = JSON.stringify(payload);
    let headers: Record<string, string> = {'Content-Type': 'application/json'};
    const {requestId, simpleRequest, withCredentials = false, xApiKey} = source;
    requestId === 'query' && (url = DiscoveryService.addRequestId(url));
    if (simpleRequest) {
      body = JSON.stringify({accessToken: context.imsToken, ...payload});
      headers = {};
      scope = FetchScope.NONE;
    }
    const metadata: DefaultMetaData = {
      apiKey: xApiKey ? context.apiKey : ''
    };
    const init: FetchInit = {
      body,
      credentials: withCredentials ? 'include' : 'omit',
      headers,
      metadata,
      method: 'POST',
      requestId: requestId === 'header' ? 'auto' : undefined,
      scope,
      totalFetchTime: 10000
    };

    const response = await fetch(url, init);
    if (response.ok) {
      try {
        const discoveryResponse: DiscoveryResponse = await response.json();
        discoveryResponse.discoveryUrl = url;
        return discoveryResponse;
      } catch (err: any) {
        err.url = url;
        throw err;
      }
    }
    throw (await this.extractDiscoveryError(response, source));
  }

  /**
   * Caches a discovery response after its complete.
   * @param {string} key - Discovery key
   * @param {DiscoveryResponse} response - Discovery Response
   * @param {DiscoveryCacheConfig} cacheConfig - Application discovery cache config
   * @returns {DiscoveryResponse} response - Response augmented with expiry data.
   * @private
   */
  private onComplete(key: string, response: DiscoveryResponse, cacheConfig: DiscoveryCacheConfig): DiscoveryResponse {
    const {limitToImsSession, scope, ttl} = cacheConfig;
    if (ttl) {
      const fulfilled = Date.now();
      const expires = fulfilled + ttl * 1000;
      response = {...response, expires, fulfilled};
      // Don't cache session data.
      const cacheResponse = {...response};
      delete cacheResponse.session;
      delete cacheResponse.sessionExpires;
      if (limitToImsSession) {
        cacheResponse.session = getSid();
      }
      // Currently support local storage only.
      if (scope === CACHE_SCOPE.LOCAL && !cacheResponse.dontCache) {
        this.metrics.event('Discovery.cacheSet', {expires, key});
        localCache.set(key, cacheResponse);
      }
    }
    return response;
  }

  /**
   * Logs an error for background, non-blocking discovery failures.
   * @param {string} key
   * @param {DiscoverySource} source - Solution discovery source definition
   * @param {Error} error - Error information
   * @private
   */
  private onError(key: string, source: DiscoverySource, error: Error & {url: string}): void {
    const message = error && error.message || 'Unknown error';
    const url = error.url ? error.url : source.discovery;
    this.metrics.event('Discovery.backgroundError', {cookie: document.cookie.length, key, message, url}, {level: Level.ERROR});
  }

  public async generateIframeSourceDiscovery(src: DiscoverySource, app: AppSandbox): Promise<DiscoverySandboxResponse | PostErrorAction> {
    const {context, metrics: appMetrics} = app;
    const {imsOrg: org, imsProfile, subOrg} = context;
    const {findHostFromContext} = src;
    let host = '';

    try {
      // Populating host of discovery call if findHostFromContext is in config.
      // Host in URL would be templated in the config, and we need to find it in product context.
      if (findHostFromContext) {
        host = this.findDomainFromContext({
          org, projectedProductContext: imsProfile?.projectedProductContext, subOrg
        }, findHostFromContext) || '';

        host || appMetrics.event('Sandbox.generateIframeSource', 'findHostFromContext could not determine host', {
          org, projectedProductContext: imsProfile?.projectedProductContext, src, subOrg
        });
      }

      src = {...src, discovery: updateTemplatedURL(src.discovery, app, {host})};
      const discovery = await this.getSourceFromDiscovery(src, context, this.getDiscoveryOptions(app));
      const {client_id, scope: scopes, url: discoveryUrl} = discovery;
      const {defaults, url} = src;
      const urlString = discovery.url || (url && updateTemplatedURL(url, app, {...src, ...(defaults || {}), ...(discovery as any)}));

      if (!urlString) {
        return this.handleDiscoveryError(
          src,
          new Error('Discovery must either return a URL, or contain a URL in the configuration'),
          app
        );
      }

      appMetrics.event('Sandbox.discoveryURL', {discovery, src, subOrg});

      const customIms = (client_id && scopes) ? {client_id, scopes} : undefined;
      return {customIms, hasUrl: !!discoveryUrl, src: new URL(urlString)};
    } catch (err: any) {
      return this.handleDiscoveryError(src, err, app);
    }
  }

  private handleDiscoveryError(source: DiscoverySource, discoveryError: DiscoveryError, app: AppSandbox): PostErrorAction {
    const {context, metrics: appMetrics, metricsAppId: appId, solutionConfig} = app;
    const {message, status = 0} = discoveryError || {};
    let error: DiscoveryErrorMessage | undefined;

    if (!app.mounted) {
      return {showErrorPage: false};
    }
    appMetrics.event('Sandbox.discoveryFailed', {appId, message}, {level: Level.ERROR});

    if (source.redirectOnFailure) {
      const env = getAppEnvironment(context, solutionConfig);
      const {pageRedirect, showToast, tenant} = context;
      const {redirectOnFailure: {code, path}} = source;
      appMetrics.event('Sandbox.discoveryFailureRedirect', {appId, code, path, tenant});
      if (code.includes(status)) {
        const redirectConfig = {env, path, replace: true, tenant};
        if (isSkyline()) {
          // For AEM Skyline, the redirect is to experience manager but
          // then the user is taken to experience.adobe.com because
          // non-Skyline apps can only load on experience.adobe.com. So a
          // query param must be added so that experience cloud knows to
          // show the toast.
          const tempUrl = new URL(`${window.location.origin}${path}`);
          tempUrl.searchParams.set('redirectToast', 'instanceRedirectNoAccess');
          redirectConfig.path = `${tempUrl.pathname}${tempUrl.search}`;
        } else {
          // Non-Skyline should trigger a toast directly.
          showToast('instanceRedirectNoAccess');
        }
        pageRedirect(redirectConfig);
        return {showErrorPage: false};
      }
    }

    // If the errorInfo object exists on the error instance and the id is
    // set, the app has specific error information to be displayed to the
    // user. Setting the error state property means that it will be used
    // in `render()` to show the error message.
    if (discoveryError?.errorInfo?.id) {
      const {errorInfo: {description, heading, id}} = discoveryError;
      error = {description, heading, id: `${app.appId}.${id}`};
    }

    return {error, showErrorPage: true};
  }
}
